import React from "react";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import HTTracking from "~/components/HTTracking/HTTracking";

function HTTrackingPage() {
  return (
    <PrivateRoute>
      <HTTracking />
    </PrivateRoute>
  );
}

export default React.memo(HTTrackingPage);
