import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCUdEqJ2D1cVvkwTJ18wcmajQWI8QHV-hs",
  authDomain: "app-ht.firebaseapp.com",
  databaseURL: "https://app-ht-default-rtdb.firebaseio.com",
  projectId: "app-ht",
  storageBucket: "app-ht.appspot.com",
  messagingSenderId: "980447915007",
  appId: "1:980447915007:web:502228cb6c145ccaf895ca",
  measurementId: "G-8BLRQG2LW0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, "app-ht");
const db = getDatabase(app);
export default db;
