import React, { useEffect, useRef, useState } from "react";

import MomentUtils from "@date-io/moment";
import { Button, InputLabel, MenuItem, Select } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { onValue, ref } from "firebase/database";
import * as L from "leaflet";
import * as moment from "moment";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import styled from "styled-components";

import LoaderErrorContainer from "~/components/Containers/LoaderErrorContainer";
import db from "~/utils/api/geolocation-firebase";
import nurseService from "~/utils/api/v1/nurseService";
import { Nurse } from "~/utils/interfaces/Nurse";

const FullWidthHeightDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
`;

const FiltersDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4rem;
`;

const PrimaryButton = styled(Button)`
  max-height: 2.5rem;
  border-color: var(--primary);
  color: var(--primary);
`;

function HTTracking() {
  const [loading, setLoading] = useState<boolean>(false);
  // const [country, setCountry] = useState<"Chile" | "México">("Chile");
  const [selectedNurseUserId, setSelectedNurseUserId] = useState<string>("");
  // const [center, setCenter] = useState<L.LatLng>(L.latLng(-33.41385135961599, -70.60334467295577));
  const [errorMessage, setErrorMessage] = useState<string>("");
  // const [pointsToShow, setPointsToShow] = useState<[number, number, number, number][]>([]);
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment);
  const [nurses, setNurses] = useState<Nurse[]>([]);
  const [position, setPosition] = useState<[number, number] | null>(null);
  const [datetime, setDatetime] = useState<string>("");

  const mapRef = useRef<L.Map>(null);
  const center = L.latLng(-33.41385135961599, -70.60334467295577);

  // const onCountryChangeHandler = (e) => {
  //   setErrorMessage("");
  //   if (e.target.value === "Chile") {
  //     setCenter(L.latLng(-33.41385135961599, -70.60334467295577)); // El Bosque Norte 500
  //   } else {
  //     setCenter(L.latLng(19.41321796604331, -99.16440678088462)); // San Luis Potosí 196
  //   }
  //   mapRef.current?.setView(center, 12);
  //   setCountry(e.target.value);
  // };

  const onVisitadorChangeHandler = (e) => {
    setErrorMessage("");
    setSelectedNurseUserId(e.target.value);
  };

  const onDateChangerHandler = (date: React.SetStateAction<moment.Moment>) => {
    setErrorMessage("");
    setSelectedDate(date);
  };

  const handleFilterButton = async () => {
    setLoading(true);
    const dbRef = ref(db, "/geolocations/" + selectedNurseUserId + "/");

    onValue(dbRef, (snapshot) => {
      try {
        const data = snapshot.val();
        const latestLatLng = data[Object.keys(data)[Object.keys(data).length - 1]];
        const timestamp = Object.keys(data)[Object.keys(data).length - 1];
        const date = new Date(+timestamp);
        const formattedDate = date.toLocaleString("es-CL");

        setDatetime(formattedDate);
        setPosition([latestLatLng["latitude"], latestLatLng["longitude"]]);
        setErrorMessage("");
      } catch (error) {
        console.log(error);
        setErrorMessage(
          "No se encontraron datos para la HT seleccionada. Por favor confirmar que permitió la ubicación en su dispositivo.",
        );
      }
    });
    setLoading(false);
  };

  // useEffect(() => {
  //   if (country === "Chile") {
  //     setCenter(L.latLng(-33.41385135961599, -70.60334467295577)); // El Bosque Norte 500
  //   } else {
  //     setCenter(L.latLng(19.41321796604331, -99.16440678088462)); // San Luis Potosí 196
  //   }
  //   mapRef.current?.setView(center);
  // }, [country]);

  useEffect(() => {
    const fetchNurses = async () => {
      setLoading(true);
      try {
        const nursesResponse = await nurseService.fetchActiveNurses("cl");
        setNurses(nursesResponse.data.data);
      } catch (err) {
        console.log(err);
        setErrorMessage("A ocurrido un error con el backend por favor comunicate con el equipo de desarrollo.");
      }
      setLoading(false);
    };

    fetchNurses();
  }, []);

  if (typeof window !== "undefined") {
    return (
      <FullWidthHeightDiv>
        <h1>Ubicación de HT en tiempo real</h1>
        <FiltersDiv>
          {!loading && (
            <>
              {/* Gonna ignore country for now */}
              {/* <div>
                <InputLabel>País</InputLabel>
                <Select
                  value={country}
                  label="País"
                  onChange={onCountryChangeHandler}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {COUNTRIES.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </div> */}
              <div>
                <InputLabel>HT</InputLabel>
                <Select
                  value={selectedNurseUserId}
                  label="Visitador"
                  onChange={onVisitadorChangeHandler}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {nurses.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.user_id}
                    >
                      {item.names} {item.last_names}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <InputLabel>Fecha para buscar</InputLabel>
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  locale="es"
                >
                  <KeyboardDatePicker
                    variant="inline"
                    format="LL"
                    value={selectedDate}
                    autoOk
                    onChange={onDateChangerHandler}
                    disableFuture
                  />
                </MuiPickersUtilsProvider>
              </div>
              <PrimaryButton
                disabled={selectedNurseUserId.length == 0}
                variant="outlined"
                color="primary"
                onClick={handleFilterButton}
              >
                Seleccionar
              </PrimaryButton>
            </>
          )}
          {loading && (
            <LoaderErrorContainer>
              <h1>Cargando...</h1>
            </LoaderErrorContainer>
          )}
        </FiltersDiv>
        {errorMessage.length > 0 && <p>{errorMessage}</p>}
        <div id="map">
          <MapContainer
            center={center}
            zoom={12}
            style={{ height: "600px" }}
            leafletRef={mapRef}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {position && (
              <Marker
                position={position}
                eventHandlers={{
                  mouseover: (e: L.LeafletMouseEvent) => {
                    e.target.openPopup(e.latlng);
                    e.target.setPopupContent(`Ultima actualización a las ${datetime}`);
                  },
                  mouseout: (e: L.LeafletMouseEvent) => {
                    e.target.closePopup();
                  },
                }}
              >
                <Popup />
              </Marker>
            )}
            {/* {Array.isArray(pointsToShow) && pointsToShow.length > 0 && (
              <>
                <Polyline
                  positions={pointsToShow.map((coordinateArray) => coordinateArray.slice(0, 2) as [number, number])}
                  pathOptions={{ weight: 8, color: "#457DF1" }}
                />

                {pointsToShow.slice(1, -1).map((point) => (
                  // eslint-disable-next-line react/jsx-key
                  <Marker
                    position={point.slice(0, 2) as [number, number]}
                    eventHandlers={{
                      mouseover: (e: L.LeafletMouseEvent) => {
                        e.target.openPopup(e.latlng);
                        e.target.setPopupContent(
                          `Location is ${e.latlng} and time is ${moment(point[2]).format("HH:MM:SS")}`,
                        );
                      },
                      mouseout: (e: L.LeafletMouseEvent) => {
                        e.target.closePopup();
                      },
                    }}
                  >
                    <Popup />
                  </Marker>
                ))}
                <Marker
                  position={pointsToShow[0].slice(0, 2) as [number, number]}
                  title="Inicio"
                  eventHandlers={{
                    mouseover: (e: L.LeafletMouseEvent) => {
                      e.target.openPopup();
                    },
                    mouseout: (e: L.LeafletMouseEvent) => {
                      e.target.closePopup();
                    },
                  }}
                >
                  <Popup>Inicio del recorrido</Popup>
                </Marker>
                <Marker
                  position={pointsToShow[pointsToShow.length - 1].slice(0, 2) as [number, number]}
                  eventHandlers={{
                    mouseover: (e: L.LeafletMouseEvent) => {
                      e.target.openPopup();
                    },
                    mouseout: (e: L.LeafletMouseEvent) => {
                      e.target.closePopup();
                    },
                  }}
                  title="Fin"
                >
                  <Popup>Fin del recorrido</Popup>
                </Marker>
              </>
            )} */}
          </MapContainer>
        </div>
      </FullWidthHeightDiv>
    );
  }
  return (
    <FullWidthHeightDiv>
      <h1>Rutas Visitadores Médicos</h1>
      <h2> cargando...</h2>
    </FullWidthHeightDiv>
  );
}

export default React.memo(HTTracking);
